<template>
  <div class="table-component tasks">
    <div class="table-component__header" v-if="canEdit">
      <ButtonStock title="Новая задача" @click="addTask" />
      <!-- <ButtonStock title="Все задачи" @click="goToTask" /> -->
    </div>
    <div class="table-vidjet">
      <table>
        <thead>
          <tr>
            <th>Задача</th>
            <th>Контрольный срок</th>
            <th>Срочность</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in taskList" :key="item.id" @dblclick="editCard(item.id)">
            <td>{{ item.name }}</td>
            <td>{{ $momentFormat(item.deadline, 'DD MMMM YYYY HH:mm') }}</td>
            <td :class="getUrgencyClass(item.taskUrgencyId)">{{ item.taskUrgency.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-component__footer">
      <ButtonStock title="Все задачи" @click="goToTask" />
    </div>

    <!-- Edit modal -->
    <ModalComponent headType="blue" title="Создать задачу" @close="dialogState = false" v-model="dialogState">
      <IssueCardAdd v-bind:nn="dialogNn" :isEap="false" v-on:save="afterSaveCard" v-on:close="dialogState = false" />
    </ModalComponent>
  </div>
</template>

<script>
  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import IssueCardAdd from '../../task/components/IssueCardAdd.vue';
  import API from '../api/widgetElement';

  export default {
    name: 'Widget3',
    components: {
      ButtonStock,
      IssueCardAdd,
      ModalComponent,
    },
    data() {
      return {
        taskList: [],
        dialogState: false,
        dialogNn: 1,
        canEdit: false,
      };
    },
    created() {
      this.loadTask();

      var profile = this.$store.state.auth?.oidc?.profile;
      if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));
      this.canEdit = profile?.employee?.id > 0;
    },
    methods: {
      loadTask() {
        API.getTasks(this.countryId).then((response) => {
          this.taskList = response.data;
        });
      },
      addTask() {
        this.dialogState = true;
      },
      afterSaveCard() {
        this.dialogState = false;
        this.loadTask();
      },
      goToTask() {
        this.$router.push({ name: 'Issues' });
      },
      editCard(id) {
        this.$router.push({ name: 'IssueCard', params: { id: id, action: this.canEdit ? 'edit' : 'view' } });
      },

      getUrgencyClass(id) {
        if (id == 3) return 'high';
        if (id == 2) return 'mid';
        if (id == 1) return 'low';
      },
    },
  };
</script>

<style lang="scss">
  @import '@/components/widgets/inners/tables/tableWidgets.scss';
</style>
